<template>
  <v-container>
    <v-row justify="center" class="mt-3">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">NID Scraper Request</h4>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" xl="8">
        <v-card>
          <div class="grey--text text-center py-15" v-if="loading">
            <v-progress-circular indeterminate size="50" />
            <div class="caption my-2">Loading parse request...</div>
          </div>
          <v-card-text v-else-if="nidScraperVoterInfo">
            <v-simple-table class="text-right">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Requested At</td>
                    <td class="text-left">
                      {{ formatDate(nidScraperVoterInfo.createdAt) }}
                    </td>
                  </tr>
                  <tr v-if="nidScraperVoterInfo.requestUserId && canShowUserInfo()">
                    <td>Requested By</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'user.view',
                          params: { id: nidScraperVoterInfo.requestUserId },
                        }"
                      >
                        {{
                          nidScraperVoterInfo.requestUserName || nidScraperVoterInfo.requestUserId
                        }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-for="item in items" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-left">
                      {{ emptyChecker(item.value(nidScraperVoterInfo)) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Photo</td>
                    <td class="text-left">
                      <img
                        :src="`data:image/png;base64,${nidScraperVoterInfo.photoBase64}`"
                        alt="Photo"
                        class="nid-image"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="nidScraperVoterInfo">
      <v-col cols="12" xl="8">
        <v-expansion-panels>
          <v-expansion-panel @change="this.getNidScraperDump">
            <v-expansion-panel-header class="text-h5 font-weight-regular">
              NID Scraper Dump
            </v-expansion-panel-header>
            <v-expansion-panel-content class="dump-panel">
              <iframe
                v-if="nidScraperDump"
                :src="'data:text/html;charset=UTF-8,' + nidScraperDump"
                width="100%"
                class="dump-frame"
                sandbox=""
              ></iframe>
              <v-progress-circular
                class="ma-10"
                v-else
                indeterminate
                size="40"
                width="4"
                color="grey"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import moment from "moment";
import { NID_SCRAPER_DUMP, NID_SCRAPER_VOTER_INFO } from "@/constants/api";

export default {
  name: "MatchRequest",
  metaInfo: { title: "Match Request" },
  components: {},
  data() {
    return {
      loading: false,
      nidScraperVoterInfo: null,
      nidScraperDump: null,
      items: [
        { name: "Nid", value: (item) => item.nid },
        { name: "Name (Bengali)", value: (item) => item.nameBn },
        { name: "Name (English)", value: (item) => item.nameEn },
        { name: "Date Of Birth", value: (item) => item.dateOfBirth },
        { name: "Blood Group", value: (item) => item.bloodGroup },
        { name: "Father Name (Bengali)", value: (item) => item.fatherNameBn },
        { name: "Father Name (English)", value: (item) => item.fatherNameEn },
        { name: "Mother Name (Bengali)", value: (item) => item.motherNameBn },
        { name: "Mother Name (English)", value: (item) => item.motherNameEn },
        { name: "Occupation (Bengali)", value: (item) => item.occupationBn },
        { name: "Occupation (English)", value: (item) => item.occupationEn },
        { name: "PIN", value: (item) => item.pin },
        { name: "Spouse Name (Bengali)", value: (item) => item.spouseNameBn },
        { name: "Spouse Name (English)", value: (item) => item.spouseNameEn },
        { name: "Present Address (Bengali)", value: (item) => item.presentAddressBn.addressLine },
        { name: "Present Address (English)", value: (item) => item.presentAddressEn.addressLine },
        {
          name: "Permanent Address (Bengali)",
          value: (item) => item.permanentAddressBn.addressLine,
        },
        {
          name: "Permanent Address (English)",
          value: (item) => item.permanentAddressEn.addressLine,
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.requestId) {
      this.getNidScraperVoterInfoRequest(this.$route.params.requestId);
    }
  },
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    getNidScraperVoterInfoRequest(requestId) {
      this.loading = true;
      return this.$axios
        .get(`${NID_SCRAPER_VOTER_INFO}?id=${requestId}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.nidScraperVoterInfo = response.data.data;
            return;
          }
          throw new Error(response.data.message);
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.push({ name: "nid-scraper.index" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getNidScraperDump() {
      if (!this.nidScraperVoterInfo || !this.nidScraperVoterInfo.requestId) {
        this.$iziToast.error({
          title: "No NID Scraper Dump",
        });
        return;
      }
      if (this.nidScraperDump) {
        return;
      }
      let requestId = this.nidScraperVoterInfo.requestId;
      return this.$axios
        .get(`${NID_SCRAPER_DUMP}?id=${requestId}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.nidScraperDump = response.data.data;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style lang="scss">
.nid-image {
  max-width: 150px;
  padding: 10px;
}
.dump-panel {
  height: 60vh;
  iframe {
    padding: 0.5rem;
    border: none;
    height: 60vh;
  }
}
</style>
